.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

nav {
  background: white;
  height: 80px;
}

nav ul {
  float: right;
  margin-right: 20px;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
  text-decoration: none;
}

nav ul li {
  display: inline-block;
  line-height: 80px;
  margin: 0 5px;
  text-decoration: none;
}

nav ul li::before {
  display: none;
}

nav ul li a {
  color: black;
  font-size: 17px;
  text-transform: uppercase;
  text-decoration: none;
}

a:hover {
  color: gray;
}

a.active {
  text-decoration:overline;
}


.logo {
  color: black;
  font-size: 35px;
  line-height: 80px;
  font-weight: bold;
  float: left;
  background-color: white;
  color: #444444;
  font-size: 2.5rem;
  font-style: normal;
  font-family: 'Raleway', sans-serif;
  font-weight: 900;
  text-transform: uppercase;
}


.Content {
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.Text-Input {
  display: inline;
  font-size: 1.25rem;
  margin-bottom: 0px;
  padding-bottom: 0px;
  margin-top:  0;
  font-family: Garamond;
}

.Text-Symbol {
  display: inline;
  font-size: 1.25rem;
  margin-bottom: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  padding-top: 0px;
  font-family: Garamond;
}


.Text-Input a:hover {
  background:#444;
  color:white;
  padding-top:2px;
  padding-bottom:2px;
  border-radius: 5px;
  text-decoration: none;
}

.Text-Input a {
  color:black;
  text-decoration: none;
}

.Text-Instructions {
  font-size: .9rem;
  display:inline-block; 
  padding-bottom: 3px;
}

.Text-Capacity {
  font-size: .9rem;
  display:inline-block; 
  padding-bottom: 3px;
  padding-right: 5px;
}


.Instructions {
  /* margin-top: 100px; */
  display:block;
}

.Instruction-Grid-Item {
  justify-content:center;
  max-height: 1.5rem;
  margin: 0;
  padding: 0;
  color:black;
}

.Instruction-Text {
  color:black;
}


@media (max-width: 952px) {
  .logo {
    font-size: 1.25rem;
    float:none;
  }
  nav {
    margin: 0;
    height: 150px;
  }
  nav ul {
    float: none;
    /* margin-right: 20px; */
    /* padding: 0; */
    background-color: white;
    margin-top: -10px;
    /* list-style: none; */
    /* box-sizing: border-box; */
  };
  nav ul li {
    /* display: none; */
    line-height: 80px;
    height:25px;
    display: block;
  }

  .Instruction-Grid-Item {
    justify-content:center;
    /* max-height: 5rem; */
    max-height: 3.5rem;
  }

  .Text-Input {
    display: inline-block;
    font-size: 1.0rem;
    margin-bottom: 0px;
    padding-bottom: 0px;
    margin-top:  0;
    font-family: Garamond;
  }

  .Text-White-Space {
    display: inline-block;
    margin-left: .2rem;
  }

  .App {
    text-align: center;
  }

  .Content {
    text-align: left;
    margin-bottom: 0px;
  }

  .Page {
    color: red;
    display: inline-block;
    margin-left: -100px;
  }

  .Text-Instructions {
    font-size: .9rem;
    display:none; 
    padding-bottom: 3px;
  }
  
  .Instructions{
    display: none;
  }
  
}

